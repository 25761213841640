.footer {
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
